import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { ComponentProps, ComponentPropsWithRef, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AdminBuildingPath, AdminPropertyPath, AdminPropertyPublishmentPath } from "..";
import { GlobalModalError, useGlobalModal } from "../../../component/GlobalModal";
import { Property, PropertyDto } from "../../../api/entities/property/property.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import { DeepPartial } from "react-hook-form";
import { useI18N } from "../../../component/I18NProvider";
import { faAddressCard, faArrowsLeftRight, faArrowsRotate, faArrowUpRightFromSquare, faBuilding, faCircleMinus, faCirclePlus, faCity, faCodeCompare, faDisplay, faEraser, faExclamationCircle, faEye, faFilePen, faLink, faMapLocationDot, faMaximize, faMinimize, faMinus, faPlus, faRotateLeft, faRotateRight, faSearch, faSquareEnvelope, faSquarePhone, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons"
import { GoogleApiMapsSearch } from "../component/GoogleApiMapsSearch";
import { GoogleApisMapsPlaceAutocompleteTypePreset } from "../../../api/entities/googleapis";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import moment from "moment";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { AdminDistrictSelector } from "./Districts";
import { AdminDevelopersSelector } from "./Developers";
import { PropertyBasicInfo, PropertyDirections, PropertyFurnishTypes } from "../../../api/entities/property/property-basic-info.entity";
import { PropertyPriceStatus, PropertySources, PropertyStatuses } from "../../../api/entities/property/property-price-status.entity";
import { PropertyContactTitle, PropertyContactTitles, PropertyContactVisibilities, PropertyLandlord, PropertyLandlordContactCount } from "../../../api/entities/property/property-landlord.entity";
import { PropertyDetail } from "../../../api/entities/property/property-detail.entity";
import { AdminBuildingPreview, AdminBuildingSelector } from "./Buildings";
import { AdminPropertyViewSelector } from "./PropertyViews";
import { AdminPropertyDecorationSelector } from "./PropertyDecorations";
import { Building, BuildingTypes } from "../../../api/entities/building.entity";
import { AdminBuildingI18NPrefix, StreetPreview } from "./Building";
import { Collapsible, CollapsibleButton } from "../../../component/Collapsible";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { Alert, Badge, Spinner } from "react-bootstrap";
import { LocalCache } from "../../../api/src/local-cache";
import { Lang, LangMap, Langs } from "../../../utils/lang-map";
import { commify, GenericInput } from "../component/GenericInput";
import { validateEmail, validatePhoneNumber } from "../../../utils/validate";
import { Link } from "react-router-dom";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { TFunction } from "react-i18next";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { AdminTable } from "../component/Table";
import "../../../styles/admin/property.scss";
import { AdminBuildingAndPropertyImageToolI18NPrefix, BuildingAndPropertyImageTool } from "../component/BuildingAndPropertyImageTool";
import { PropertyPdfGenerator } from "../component/PropertyPdfGenerator";
import { TimestampEntity } from "../../../api/entities/timestamp.entity";
import { TimestampEntityWithUser } from "../../../api/entities/timestamp-with-user.entity";
import { ContactShortcut } from "../component/ContactShortcut";
import { createNumberArray } from "../../../utils/create-number-array";
import { NullDisplay } from "../../../component/NullDisplay";
import { roundToDp } from "../../../utils/round-to-dp";
import { PropertyView } from "../../../api/entities/property/property-view.entity";
import { PropertyDecoration } from "../../../api/entities/property/property-decoration.entity";
import { useUndoRedo } from "../../../utils/use-undo-redo";
import { APIEncodeQueryString } from "../../../api/src/helpers";
import { createEntityFilter, FindAllQuery } from "../../../api/entities/findAllQuery";
import { useCollisionCheck } from "../../../utils/use-collision-check";
import _ from "lodash";
import { AdminPropertyPublishmentComponentInline, PublishmentStateDisplay } from "./PropertyPublishment";
import { useScreenSize } from "../../../utils/use-screen-size";
import { PropertyPublishment } from "../../../api/entities/property/property-publishment.entity";
import { User, userHasAdminRoles } from "../../../api/entities/user.entity";
import { ScreenSizeButton } from "../component/ScreenSizeButton";
import { APIInstanceWithAuth } from "../../../api";
import { useLoadingManager } from "../../../utils/loading-manager";
import { Form3Or5Generator } from "../component/Form3Or5Generator";
import { numberInShortPublic } from "../../../utils/number-in-short";

export interface AdminPropertyProps {
  mode: 'create' | 'edit';
}

export const AdminPropertyI18NPrefix = "admin.property.";


export const AdminPropertyStar = (props: {
  starred: boolean,
  onUpdate?: () => Promise<void> | void,
} & Partial<FontAwesomeIconProps>) => {
  const {starred, onUpdate, ...iconProps} = props;
  const loading = useLoadingManager();
  const icon = useMemo(() => {
    return starred ? faStar : faStarEmpty
  }, [starred])
  return loading.flag ? (
    <Spinner animation="border" size="sm" />  
  ) : (
    <FontAwesomeIcon icon={icon} fixedWidth {...iconProps} className={clsx("property-star", starred ? "text-warning": "text-black-50", iconProps?.className)}
      onClick={async() => {
        if (onUpdate) {
          const token = loading.start();
          try {
            await onUpdate();
          } catch (e) {

          }
          loading.stop(token);
        }
      }}
    />
  )
}

export const PropertyBasicInfoPreview = (t: TFunction, parts: {
  building?: Building,
  block?: string,
  floor?: string,
  flat?: string,
  excludeBuildingName?: boolean,
  excludeStreet?: boolean,
  lang: Lang
}) => {
  const {building, block, floor, flat, lang} = parts;

  const street = StreetPreview(t, {
    district: building?.district?.["name" + LangMap(lang)],
    streetName: building?.["streetName" + LangMap(lang)],
    streetNumber: building?.streetNumber,
    lang
  }) 

  const delimiter = t(`${AdminPropertyI18NPrefix}basicInfo.preview.delimiter`, {lng: lang});
  const array = t(`${AdminPropertyI18NPrefix}basicInfo.preview.parts`, {
    street: (!parts.excludeStreet && street) || "", 
    buildingName: (!parts.excludeBuildingName && building?.["name" + LangMap(lang)]) || "",
    block: (block && block.trim() != "" && t(`${AdminPropertyI18NPrefix}basicInfo.preview.block`, {block, lng: lang})) || "", 
    floor: (floor && t(`${AdminPropertyI18NPrefix}basicInfo.preview.floor`, {floor: floor.toString(), lng: lang })) || "", 
    flat: (flat && flat.trim() != "" && t(`${AdminPropertyI18NPrefix}basicInfo.preview.flat`, {flat, lng: lang})) || "", 
    lng: lang, returnObjects: true, interpolation: {escapeValue: false}
  }) as string[];

  return array?.filter?.(str => str && str.trim() != "").map(str => str.trim()).join(delimiter) ?? "";
}

export const PropertyBasicInfoRoomPreview = (t: TFunction, parts: {
  bedroom: number,
  livingRoom: number,
  lang: Lang
}) => {
  const {bedroom, livingRoom, lang} = parts;
  const delimiter = t(`${AdminPropertyI18NPrefix}basicInfo.roomsDelimiter`, {lng: lang});
  const array = t(`${AdminPropertyI18NPrefix}basicInfo.roomsCount`, {
    bedroomCount: bedroom != null && t(`${AdminPropertyI18NPrefix}basicInfo.bedroomCount`, {count: bedroom, lng: lang}) || "", 
    livingRoomCount: livingRoom != null && t(`${AdminPropertyI18NPrefix}basicInfo.livingRoomCount`, {count: livingRoom, lng: lang}) || "", 
    lng: lang, returnObjects: true, interpolation: {escapeValue: false}
  }) as string[];

  return array?.filter?.(str => str && str.trim() != "").map(str => str.trim()).join(delimiter) ?? "";
}

export const PropertyBasicInfoCarparksPreview = (t: TFunction, parts: {
  coveredCarParkCount?: number,
  coveredCarParkInfo?: string,
  uncoveredCarParkCount?: number,
  uncoveredCarParkInfo?: string,
  separator?: string,
  lang?: Lang
}) => {
  const {separator, lang} = parts;
  return ([
    parts.coveredCarParkCount && (
      t(AdminPropertyI18NPrefix + "basicInfo.coveredCarParkCount", {count: parts.coveredCarParkCount, lng: lang}) +
      (parts.coveredCarParkInfo != "" ? ` (${parts.coveredCarParkInfo})` : "")
    ),
    parts.uncoveredCarParkCount && (
      t(AdminPropertyI18NPrefix + "basicInfo.uncoveredCarParkCount", {count: parts.uncoveredCarParkCount, lng: lang}) +
      (parts.uncoveredCarParkInfo != "" ? ` (${parts.uncoveredCarParkInfo})` : "")
    )
  ].filter(str => (str && str != "")).join(separator || " / "))
}

export const PropertyPriceStatusPreview = (t: TFunction, priceStatus: PropertyPriceStatus) => {

  const isSale = ["Sale", "Sold", "SaleAndLease"].includes(priceStatus?.status);
  const isRent = ["Lease", "Leased", "SaleAndLease"].includes(priceStatus?.status);
  const price = priceStatus?.price;
  const rent = priceStatus?.rent;
  const sto = t(AdminPropertyI18NPrefix + "priceStatus.sto");
  return (
    (priceStatus?.status != null) && <>
      <div>
        {
          priceStatus?.status != null && `[${t(AdminPropertyI18NPrefix + `priceStatus.statuses.${priceStatus?.status}`)}] `
        }
        {
          isSale && `${t(AdminPropertyI18NPrefix + "priceStatus.priceShort")}: ${
            price != null ? `$${commify(price)
          }` : sto}${
            priceStatus?.pricePerGrossArea && `　G$${commify(Math.round(priceStatus?.pricePerGrossArea))}`
          }${
            priceStatus?.pricePerNetArea && `　N$${commify(Math.round(priceStatus?.pricePerNetArea))}`
          }`
        }
      </div>
      <div>
        {
          isRent && `${t(AdminPropertyI18NPrefix + "priceStatus.rentShort")}: ${
            rent != null ? `$${commify(rent)
          }` : sto}${
            priceStatus?.rentPerGrossArea && `　G$${commify(Math.round(priceStatus?.rentPerGrossArea))}`
          }${
            priceStatus?.rentPerNetArea && `　N$${commify(Math.round(priceStatus?.rentPerNetArea))}`
          }`
        }
      </div>
    </>
  )
}

export const LandlordContactPreview = (t: TFunction, parts: {
  contactTitle?: PropertyContactTitle,
  contactName?: string,
  lang?: Lang | string
}) => {
  let {contactTitle, contactName, lang} = parts;
  const contactTitleStr = contactTitle ? t(`${AdminPropertyI18NPrefix}landlord.contactTitles.${contactTitle}`) : "";
  contactName = contactName.trim();

  const delimiter = t(`${AdminPropertyI18NPrefix}landlord.contactPreview.delimiter`, {lng: lang});
  const array = t(`${AdminPropertyI18NPrefix}landlord.contactPreview.parts`, {
    contactName, contactTitle: contactTitleStr, lng: lang, returnObjects: true
  }) as string[];

  // console.log(array);

  return array?.filter?.(str => str && str.trim() != "").map(str => str.trim()).join(delimiter) ?? null;
}

export const LandlordContactsPreview = (props: {
  t: TFunction, brief?: boolean, landlord: PropertyLandlord
} & ComponentPropsWithRef<"div">) => {
  const {t, brief, landlord, ...divProps} = props;
  return (
    <div {...divProps}>
    {
      brief ? <span className="text-black-50">{landlord?.contactName1 || <NullDisplay />}: {landlord?.contactInfo1?.[0] || <NullDisplay />} ...</span>  : (
        createNumberArray(1, PropertyLandlordContactCount).map(i => {
          if (landlord?.["contactName" + i] || 
            (landlord?.["contactInfo" + i]?.[0] && landlord?.["contactInfo" + i]?.[0]?.trim() != "")
          ) {
            const name = LandlordContactPreview(t, {
              contactName: landlord?.["contactName" + i],
              contactTitle: landlord?.["contactTitle" + i]
            });
            
            return (
              <div key={i} className="d-flex flex-column align-items-center" style={{gap: "0.3rem"}}>
                {name && <div><FontAwesomeIcon className="me-2" color="darkgray" icon={faAddressCard} />{name}:</div>}
    
                {landlord?.["contactInfo" + i].map((contactInfo: string, index) => contactInfo && contactInfo?.trim() != "" && (
                  <div key={index} className="d-flex align-items-center">
                    {contactInfo}
                    <ContactShortcut className="ms-2" contact={contactInfo} />
                  </div>
                ))}
              </div>
            )
          } else {
            return <React.Fragment key={i} />;
          }
        })
      )
    }</div>
  )
}
const HistoryControl = ({
  t, loading, open, selected, timestamp, onRefresh, onApply, onCancel
}: {
  t: TFunction,
  loading: boolean,
  open: boolean,
  selected: boolean,
  timestamp: Pick<TimestampEntityWithUser, "updated" | "updatedBy">
  onRefresh: () => void,
  onApply: () => void,
  onCancel: () => void,
}) => {
  return (<>
    <div className="d-flex align-items-center flex-wrap" style={{gap: "0.3rem"}}>
      {/* <ButtonWithLoader
        faIcon={faArrowsRotate}
        variant="secondary"
        loading={loading}
        size="sm"
        onClick={() => onRefresh()}
      >
      </ButtonWithLoader> */}
      <EntityTimestamp
        className="mx-2"
        updated={timestamp.updated}
        updatedBy={timestamp.updatedBy}
        showText
      />
    </div>
    {
      open && selected && <div className="d-flex align-items-center flex-wrap" style={{gap: "0.3rem"}}>
        <ButtonWithLoader
          faIcon={faFilePen}
          loading={loading}
          disabled={!selected}
          variant="success"
          size="sm"
          onClick={() => onApply()}
        >{t('entityData.history.apply')}
        </ButtonWithLoader>
        <ButtonWithLoader
          loading={loading}
          disabled={!selected}
          variant="warning"
          size="sm"
          onClick={() => onCancel()}
        >{t('entityData.history.cancel')}
        </ButtonWithLoader>
      </div>
    }
  </>)
}




export const AdminPropertyComponent = (props: AdminPropertyProps & {
  id: number,
  goToPublishment?: boolean,
  onDelete?: () => void,
  onBackToOverride?: (payload?: any) => void,
  onSave?: (property: Property) => void,
}) => {
  const navigate = useNavigate();
  const {api, loading, toast, setSelectedItem, adminUser} = useAdminComponent();
  const modal = useGlobalModal();
  const {t, currentLang} = useI18N();
  const {mode, id, onDelete, onBackToOverride} = props;
  
  const {screenSize, toggleScreenSize} = useScreenSize();

  useCollisionCheck(id && "property: " + id, (id) => {
    modal.warning(t("admin.commons.collisionAlert.title"), t("admin.commons.collisionAlert.bodyWithId", {id}))
  });

  // console.log(idRaw);

  const isNew = useMemo(() => (mode == "create"), [id]);
  
  const nameT2SLoading = useAbortableLoading();
  const streetNameT2SLoading = useAbortableLoading();

  // const userCanViewPublishment = useMemo(() => {
  //   return userHasAdminRoles(adminUser, ["PropertyPublishmentViewer"])
  // }, [adminUser])

  const userCanPublishPublishment = useMemo(() => {
    return userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"])
  }, [adminUser])

  const userCanGenerateForm = useMemo(() => {
    return userHasAdminRoles(adminUser, ["PropertyForm3_5Viewer"])
  }, [adminUser])

  

  // States
  const [unsaved, setUnsaved] = useState(false);
  const [property, setProperty] = useState<Property | null>(null);
  const [propertyViews, setPropertyViews] = useState<PropertyView[]>(null);
  const [propertyDecorations, setPropertyDecorations] = useState<PropertyDecoration[]>(null);

  const {basicInfo, priceStatus, landlord, detail, publishment} = useMemo(() => property || {
    basicInfo: new PropertyBasicInfo, 
    priceStatus: new PropertyPriceStatus, 
    landlord: new PropertyLandlord, 
    detail: new PropertyDetail,
    publishment: null,
  } as Property, [property]);

  const publishedLink = useMemo(() => {
    return property?.publicClone?.publishment?.state === "published" ? `${process.env.REACT_APP_PUBLIC_URL}/property/${property.id}` : null
  }, [property]);

  const {initializeEditStacks, pushEditStacks, canUndo, canRedo, undo, redo} = useUndoRedo({
    basicInfo: property?.basicInfo, 
    priceStatus: property?.priceStatus, 
    landlord: property?.landlord, 
    detail: property?.detail,
    publishment: property?.publishment
  });

  const updateProperty = useCallback((...[obj]: Parameters<typeof setProperty>) => {
    setProperty(oldObj => {
      const newObj = typeof obj == "object" ? obj : obj(oldObj);
      const {basicInfo, priceStatus, landlord, detail} = newObj;
      pushEditStacks(oldEdit => ({basicInfo, priceStatus, landlord, detail, publishment}));
      return newObj;
    });
    setUnsaved(true);
  }, [pushEditStacks]);
  const openCCT2SRemarkLoading = useAbortableLoading();

  // const [basicInfo, setBasicInfo] = useState<PropertyBasicInfo>(new PropertyBasicInfo);
  // const [priceStatus, setPriceStatus] = useState<PropertyPriceStatus>(new PropertyPriceStatus);
  // const [landlord, setLandlord] = useState<PropertyLandlord>(new PropertyLandlord);
  // const [detail, setDetail] = useState<PropertyDetail>(new PropertyDetail);


  const [basicInfoHistory, setBasicInfoHistory] = useState<PropertyBasicInfo[]>(null);
  const [priceStatusHistory, setPriceStatusHistory] = useState<PropertyPriceStatus[]>(null);
  const [landlordHistory, setLandlordHistory] = useState<PropertyLandlord[]>(null);
  const [detailHistory, setDetailHistory] = useState<PropertyDetail[]>(null);

  const [basicInfoHistoryPreview, setBasicInfoHistoryPreview] = useState<PropertyBasicInfo>(null);
  const [priceStatusHistoryPreview, setPriceStatusHistoryPreview] = useState<PropertyPriceStatus>(null);
  const [landlordHistoryPreview, setLandlordHistoryPreview] = useState<PropertyLandlord>(null);
  const [detailHistoryPreview, setDetailHistoryPreview] = useState<PropertyDetail>(null);
  
  const basicInfoDisabled = useMemo(() => !!basicInfoHistoryPreview, [basicInfoHistoryPreview]);
  const priceStatusDisabled = useMemo(() => !!priceStatusHistoryPreview, [priceStatusHistoryPreview]);
  const landlordDisabled = useMemo(() => !!landlordHistoryPreview, [landlordHistoryPreview]);
  const detailDisabled = useMemo(() => !!detailHistoryPreview, [detailHistoryPreview]);

  const nullPreview = useMemo(() => <i className="text-black-50">-</i>, []);

  // const localCache = new LocalCache<{
  //   basicInfoCollapsed: boolean,
  //   priceStatusCollapsed: boolean,
  //   landlordCollapsed: boolean,
  //   detailCollapsed: boolean,
  // }>({
  //   prefix: 'admin.property.'
  // });

  const [basicInfoCollapsed, setBasicInfoCollapsed] = useState(!isNew);
  const [priceStatusCollapsed, setPriceStatusCollapsed] = useState(!isNew);
  const [landlordCollapsed, setLandlordCollapsed] = useState(!isNew);
  const [detailCollapsed, setDetailCollapsed] = useState(!isNew);
  const [publishmentCollapsed, setPublishmentCollapsed] = useState(!props.goToPublishment);

  const toggleBasicInfoCollapsed = useCallback(async(newFlag?: boolean) => {setBasicInfoCollapsed((flag: boolean) => {
      return newFlag ?? !flag;
  })}, []);
  const togglePriceStatusCollapsed = useCallback((newFlag?: boolean) => setPriceStatusCollapsed((flag: boolean) => {
    // localCache.set({priceStatusCollapsed: newFlag ?? !flag});
    return newFlag ?? !flag;
  }), []);
  const toggleLandlordCollapsed = useCallback((newFlag?: boolean) => setLandlordCollapsed((flag: boolean) => {
    // localCache.set({landlordCollapsed: newFlag ?? !flag});
    return newFlag ?? !flag;
  }), []);
  const toggleDetailCollapsed = useCallback((newFlag?: boolean) => setDetailCollapsed((flag: boolean) => {
    // localCache.set({detailCollapsed: newFlag ?? !flag});
    return newFlag ?? !flag;
  }), []);
  const togglePublishmentCollapsed = useCallback((newFlag?: boolean) => setPublishmentCollapsed((flag: boolean) => {
    // localCache.set({detailCollapsed: newFlag ?? !flag});
    return newFlag ?? !flag;
  }), []);
  const historyDateFormat = "DD/MM/YYYY HH:mm";

  const areaRatio: number | null = useMemo(() => (
    (basicInfo?.grossArea != null && basicInfo?.netArea != null) ? (
      basicInfo.netArea / basicInfo.grossArea
    ) : null
  ), [basicInfo?.grossArea, basicInfo?.netArea]);
  
  const areaRatioDisplay: string | null = useMemo(() => (
    areaRatio != null && !isNaN(areaRatio) ? roundToDp(areaRatio * 100, 2) + "%" : null
  ), [areaRatio]);

  // TODO: Delete
  // const landlordContactInfoPostfices: {[i: number]: ReactNode[]} = useMemo(() => {
  //   return [1,2,3,4].reduce((acc, id) => {
  //     const contactInfo: string | null = ((!landlordCollapsed && landlordHistoryPreview) || landlord)?.["contactInfo" + id];
  //     const email = validateEmail(contactInfo)
  //     if (email) {
  //       return {...acc, [id]: (
  //         <div className="d-flex ms-2" style={{fontSize: "1.8rem", gap: "0.3rem"}} onClick={event => event.stopPropagation()}>
  //           <a href={"mailto:" + email} target="_blank" className="text-info">
  //             <FontAwesomeIcon icon={faSquareEnvelope} />
  //           </a>
  //         </div>
  //       )};
  //     } else {
  //       const phoneNumber = validatePhoneNumber(contactInfo);
  //       if (phoneNumber) {
  //         return {...acc, [id]: (
  //           <div className="d-flex ms-2" style={{gap: "0.6rem", fontSize: "2rem", lineHeight: "2rem"}} onClick={event => event.stopPropagation()}>
  //             <a href={"tel:" + phoneNumber} target="_blank" className="text-info">
  //               <FontAwesomeIcon icon={faSquarePhone} />
  //             </a>
  //             <a href={"https://wa.me/" + phoneNumber} target="_blank" className="text-success">
  //               <FontAwesomeIcon icon={faWhatsappSquare} />
  //             </a>
  //           </div>
  //         )};
  //       }
  //     }
  //     return acc;
  //   }, {})
  // }, [landlordHistoryPreview, landlord, landlordCollapsed])

  const [showContactCount, setShowContactCount] = useState(1);


  // console.log(landlordContactInfoPostfices);

  const reloadPropertyViews = useCallback(async() => {
    setPropertyViews(await api.property.findAllViews());
  }, []);

  const reloadPropertyDecorations = useCallback(async() => {
    setPropertyDecorations(await api.property.findAllDecorations());
  }, []);

  const reload = useCallback(async(clearEditStacks = false) => {
    let property: Property = null;
    let token = loading.start();
    try {
      await reloadPropertyViews();
      await reloadPropertyDecorations();
      property = await api.property.findById(id);

      if (!userCanPublishPublishment && property.publishment && property.publishment?.state == "published") {
        // Removed 20230511
        // If user cannot publish, set publishment state to "pending"
        // property.publishment.state = "pending";
      }

      setProperty(property);


      console.log({property});
      const {basicInfo, priceStatus, landlord, detail, publishment} = property;
      const editStackClone = {basicInfo, priceStatus, landlord, detail, publishment};

      
      if (clearEditStacks) {
        initializeEditStacks(editStackClone);
      } else {
        if (canUndo) {
          undo();
          pushEditStacks(() => (editStackClone));
        }
      }

      setBasicInfoHistory(await api.property.findBasicInfoHistoryById(property.id));
      setBasicInfoHistoryPreview(null);
      setPriceStatusHistory(await api.property.findPriceStatusHistoryById(property.id));
      setPriceStatusHistoryPreview(null);
      setLandlordHistory(await api.property.findLandlordHistoryById(property.id));
      setLandlordHistoryPreview(null);
      setDetailHistory(await api.property.findDetailHistoryById(property.id));
      setDetailHistoryPreview(null);

      let showContactCount = 1;

      for (let i = PropertyLandlordContactCount; i > 1; --i) {
        if (property.landlord["contactName" + i] != "" || property.landlord["contactTitle" + i] || property.landlord["contactInfo" + i].length) {
          showContactCount = i;
          break;
        }
      }

      setShowContactCount(showContactCount);

    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
    return {property};

  }, [id, userCanPublishPublishment]);

  // console.log({showContactCount});

  
  const initPublishment = useCallback(async() => {
    // Get default contact user first
    let defaultContactUser: User = null;
    try {
      defaultContactUser = await api.property.getDefaultContactUser();

    } catch (e) {

    }
    updateProperty(property => ({
      ...property,
      publishment: {
        ...new PropertyPublishment,
        titleEn: property.basicInfo?.building?.nameEn || "",
        titleTc: property.basicInfo?.building?.nameTc || "",
        titleSc: property.basicInfo?.building?.nameSc || "",
        googleMapQuery: (
          [
            property.basicInfo?.building?.district?.nameTc,
            property.basicInfo?.building?.streetNameTc,
            property.basicInfo?.building?.nameTc
          ].filter(str => !!str).join(" ")
        ),
        propertyId: id,
        contactUsers: defaultContactUser ? [{user: defaultContactUser, userId: defaultContactUser.id}] : [],
        tags: [],
        state: "pending",
      }
    }));
  }, [])

  // Initialize
  useEffect(() => {
    setSelectedItem(AdminPropertyPath);
    if (mode == "edit") {
      reload(true).then(({property}) => {
        // console.log("HI");

        if (props.goToPublishment) {
          console.log({property});
          if (!property?.publishment) {
            initPublishment();
          }
          
          setPublishmentCollapsed(false);
          setTimeout(() => {
            
            const publishmentArea = document.getElementById("publishment-area");
            const publishmentAreaY = publishmentArea?.getBoundingClientRect()?.top + window.pageYOffset - 18;
            window.scroll({top: publishmentAreaY, behavior: "smooth"});
          }, 100)
        }
      })
    } else {
      setProperty({
        id: null,
        basicInfo: new PropertyBasicInfo,
        priceStatus: new PropertyPriceStatus,
        landlord: new PropertyLandlord,
        detail: new PropertyDetail,
        updated: null,
        updatedBy: null,
        created: null,
        createdBy: null,
      });
    }
  }, [id]);

  const title = useMemo(() => {
    switch (mode) {
      case "create": 
        return t(AdminPropertyI18NPrefix + 'createTitle');
      case "edit":
        return `${t(AdminPropertyI18NPrefix + 'editTitle')} (id: ${property?.id ?? "-"})`
    }
  }, [property?.id, mode]);
  
  useEffect(() => {
    if (onBackToOverride) {
      const keyDown = async(e: KeyboardEvent) => {
        switch (e.key) {
          case "Escape":
          case "PageUp": 
          case "PageDown":
            if (!unsaved || await modal.confirmUnsaved()) {
              e.preventDefault();
              onBackToOverride(e.key);
            }
          break;
        }
      }

      window.addEventListener("keydown", keyDown);
      return () => {
        window.removeEventListener("keydown", keyDown);
      }
    }
  }, [onBackToOverride, unsaved]);


  // Render
  if (mode == 'create' && property?.id) {
    // Redirect to the property page for NEWLY CREATED entity
    return <Navigate to={`../${property.id}`} replace />
  } 

  return (
    <>
      <AdminHeaderTitle>{title}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          backTo={`../../${AdminPropertyPath}`}
          onBackToOverride={onBackToOverride ? async() => {
            if (!unsaved || await modal.confirmUnsaved()) {
              onBackToOverride();
            }
          } : null}
          faIcon={faCity}
        >
          {title}
          {property && property.id && (
            <div className="ms-2">
              <AdminPropertyStar starred={property.starred} 
                onUpdate={async() => {
                  try {
                    const updatedProperty = await api.property.updateStar(property.id, !property.starred);
                    setProperty(p => ({...p, starred: updatedProperty.starred}))
                  } catch (e) {

                  }
                }}
              />
            </div>
            
          )}
        </AdminComponentTitle>
        <ScreenSizeButton screenSize={screenSize} toggleScreenSize={toggleScreenSize} />
      </AdminComponent.TitleBar>
      <div className="hr"></div>
      {
        <EntityData
          className={clsx("container-fluid")}
          width={screenSize}
          unsaved={unsaved}
          object={property}
          loading={loading.flag} 
          // timestamp={(mode != "create") && {
          //   updated: property?.updated,
          //   updatedBy: property?.updatedBy?.username,
          //   created: property?.createdAt,
          //   createdBy: property?.createdBy?.username
          // }}
          meta={{
            // "hr": {
            //   type: "hr",
            // },
            "publishedWarning": {
              hidden: property?.publicClone?.publishment?.state !== "published",
              type: "entireComponent",
              component: (
                <Alert variant="success" className="mb-0">
                  <div>
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                    {t(AdminPropertyI18NPrefix + "publishment." + (userCanPublishPublishment ? "publishedWarningAdmin" : "publishedWarning"))}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faLink} className="me-2" />
                    {t(AdminPropertyI18NPrefix + "publishment.publishedLink")}{`: `}
                    <a href={publishedLink} target="_blank">{publishedLink}</a>
                  </div>
                </Alert>
              )

            },
            "history": {
              hidden: isNew,
              type: "entireComponent",
              component: (
                <div>
                  <EntityTimestamp
                    className="mx-2 mt-2"
                    timestamp={property}
                    showText
                  />
                </div>
              )
            },
            "basicInfo": {
              type: "section",
              title: <CollapsibleButton 
                title={t(AdminPropertyI18NPrefix + "basicInfo.title")} 
                collapsed={basicInfoCollapsed} 
              />,
              onClick: () => toggleBasicInfoCollapsed()
            },
            "basicInfoSection": {
              type: "entireComponent",
              component: (
                <Collapsible
                  collapsed={basicInfoCollapsed}
                  onExpand={() => toggleBasicInfoCollapsed(false)}
                  collapsedComponent={basicInfo && basicInfoCollapsed && <>
                    {
                      basicInfo?.building && (<div className="d-flex align-items-center justify-content-center text-center">
                        {
                          PropertyBasicInfoPreview(t, {
                            building: basicInfo.building,
                            block: basicInfo.block,
                            floor: basicInfo.floor,
                            flat: basicInfo.flat,
                            lang: currentLang as Lang
                          })
                        }
                        {
                          basicInfo?.building && (
                            <a href={`https://www.google.com/maps/search/${
                              PropertyBasicInfoPreview(t, {
                                building: basicInfo.building,
                                block: basicInfo.block,
                                lang: currentLang as Lang
                              })
                            }`} target="_blank" className="d-inline-flex align-items-center fw-bold text-decoration-none ms-1" onClick={(event) => event.stopPropagation()}>
                              <FontAwesomeIcon icon={faMapLocationDot} size="sm" className="p-1" />Map
                            </a>
                          )
                        }
                      </div>)
                    }
                    {
                      (basicInfo?.grossArea != null || basicInfo?.netArea != null) && (
                        <div className="text-center">
                          {[
                            basicInfo?.grossArea != null && `${commify(basicInfo?.grossArea)}' (${t(AdminPropertyI18NPrefix + "basicInfo.grossAreaShort")})`,
                            basicInfo?.netArea != null && `${commify(basicInfo?.netArea)}' (${t(AdminPropertyI18NPrefix + "basicInfo.netAreaShort")})`
                          ].filter(obj => !!obj).join(", ")}
                        </div>
                      )
                    }
                    {
                      basicInfo && PropertyBasicInfoCarparksPreview(t, {
                        coveredCarParkCount: basicInfo?.coveredCarParkCount,
                        coveredCarParkInfo: basicInfo?.coveredCarParkInfo,
                        uncoveredCarParkCount: basicInfo?.uncoveredCarParkCount,
                        uncoveredCarParkInfo: basicInfo?.uncoveredCarParkInfo,
                      })
                      // (basicInfo?.coveredCarParkCount != null || basicInfo?.coveredCarParkInfo) && (
                      //   <div className="text-center">
                      //     {t(AdminPropertyI18NPrefix + "basicInfo.coveredCarPark")}{`: `}
                      //     {basicInfo?.coveredCarParkCount || <NullDisplay />}
                      //     {basicInfo?.coveredCarParkInfo && ` [${basicInfo?.coveredCarParkInfo}]`}
                      //   </div>
                      // )
                    }
                    {/* {
                      (basicInfo?.uncoveredCarParkCount != null || basicInfo?.uncoveredCarParkInfo) && (
                        <div className="text-center">
                          {t(AdminPropertyI18NPrefix + "basicInfo.coveredCarPark")}{`: `}
                          {basicInfo?.uncoveredCarParkCount || <NullDisplay />}
                          {basicInfo?.uncoveredCarParkInfo && ` [${basicInfo?.uncoveredCarParkInfo}]`}
                        </div>
                      )
                    } */}
                  </>}
                >
                  <EntityData
                    noMarginCompensate
                    object={basicInfoHistoryPreview || basicInfo}
                    disabled={basicInfoDisabled}
                    loading={loading.flag}
                    meta={{
                      "history": {
                        hidden: isNew,
                        type: "entireComponent",
                        component: <>
                          {
                            basicInfoHistory && (
                              <div className="property-history">
                                <AdminTable
                                  sticky
                                  keys={{
                                    date: {content: t('entityData.history.date'), shrink: true},
                                    user: {content: t('entityData.history.user'), shrink: true},
                                    building: {content: t(AdminBuildingI18NPrefix + "title")},
                                    block: {content: t(AdminPropertyI18NPrefix + "basicInfo.block")},
                                    floor: {content: t(AdminPropertyI18NPrefix + "basicInfo.floor")},
                                    flat: {content: t(AdminPropertyI18NPrefix + "basicInfo.flat")},
                                    grossArea: {content: t(AdminPropertyI18NPrefix + "basicInfo.grossAreaShort")},
                                    netArea: {content: t(AdminPropertyI18NPrefix + "basicInfo.netAreaShort")},
                                  }}
                                  rows={basicInfoHistory.map(obj => {
                                    const {building} = obj;
                                    const selected = obj == basicInfoHistoryPreview;
                                    return {
                                      selected,
                                      onClick: () => {
                                        setBasicInfoHistoryPreview(oldObj => {
                                          if (obj == oldObj) {
                                            // Replace
                                            // updateBasicInfo(obj);
                                            updateProperty(p => ({...p, basicInfo: oldObj}))
                                            return null;
                                          } else {
                                            return obj;
                                          }
                                        });
                                      },
                                      content: {
                                        date: moment(obj.updated).format(historyDateFormat),
                                        user: obj.updatedBy?.username,
                                        building: building ? (
                                          building?.["name" + LangMap(currentLang)] + ", " +
                                          StreetPreview(t, {
                                            district: building?.district?.["name" + LangMap(currentLang)],
                                            streetName: building?.["streetName" + LangMap(currentLang)],
                                            streetNumber: building?.["streetNumber"]
                                          })
                                        ) : nullPreview,
                                        block: (obj.block && obj.block != "") ? obj.block : nullPreview,
                                        floor: (obj.floor != null) ? obj.floor : nullPreview,
                                        flat: (obj.flat && obj.flat != "") ? obj.flat : nullPreview,
                                        grossArea: obj.grossArea != null ? commify(obj.grossArea) + "'" : nullPreview,
                                        netArea: obj.grossArea != null ? commify(obj.netArea) + "'" : nullPreview
                                      }
                                    }
                                  })}
                                />
                              </div>
                            )
                          }
                          <HistoryControl t={t} loading={loading.flag} 
                            open={!!basicInfoHistory} 
                            selected={!!basicInfoHistoryPreview} 
                            timestamp={basicInfoHistoryPreview || basicInfo}
                            onRefresh={async() => {
                              setBasicInfoHistoryPreview(null);
                              const token = loading.start();
                              try {
                                setBasicInfoHistory(await api.property.findBasicInfoHistoryById(property.id));
                              } catch (e) {
                                modal.errorSpecific(e);
                              }
                              loading.stop(token);
                            }}
                            onApply={() => {
                              updateProperty(p => ({...p, basicInfo: basicInfoHistoryPreview}))
                              setBasicInfoHistoryPreview(null);
                            }}
                            onCancel={() => setBasicInfoHistoryPreview(null)}                            
                          />
                        </>
                      },
                      "building": {
                        type: "valueComponent",
                        title: <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + "basicInfo.building")}
                          {
                            basicInfo?.building?.id && (
                              <Link target="_blank" to={"../../" + AdminBuildingPath + "/" + basicInfo?.building?.id} className="ms-2">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" />
                              </Link>
                            )
                          }
                          {
                            basicInfo?.building?.id && (
                              <Link target="_blank" to={"..?" + APIEncodeQueryString({...FindAllQuery.toJson({
                                filter: {"building.id": createEntityFilter("exact", basicInfo.building.id)},
                                sort: {
                                  "basicInfo.flat": "ASC",
                                  "basicInfo.floor": "ASC",
                                  "basicInfo.block": "ASC",
                                }
                              }), includeHistory: true, query: basicInfo?.building?.["name" + LangMap(currentLang)] || null})} className="ms-2">
                                <FontAwesomeIcon icon={faSearch} size="lg" />
                              </Link>
                            )
                          }
                          
                        </div>,
                        divWidth: {xs: 12, sm: 6},
                        component: (
                          <AdminBuildingSelector
                            item={(basicInfoHistoryPreview || basicInfo).building}
                            onChange={(building) => {
                              // updateBasicInfo(basicInfo => ({...basicInfo, building, buildingType: building?.type}))
                              updateProperty(p => ({...p, basicInfo: {...p.basicInfo, building, buildingType: building?.type}}))
                            }}
                            disabled={basicInfoDisabled}
                          />
                        )
                      },
                      "buildingType": {
                        type: "select",
                        title: <div className="d-flex align-items-center">
                        {t(AdminPropertyI18NPrefix + "basicInfo.buildingType")}
                        {
                          basicInfo?.buildingType && (
                            <Link target="_blank" to={"..?" + APIEncodeQueryString({...FindAllQuery.toJson({
                              filter: {"basicInfo.buildingType": createEntityFilter("exact", basicInfo.buildingType)},
                            }), includeHistory: true, query: basicInfo.buildingType})} className="ms-2"><FontAwesomeIcon icon={faSearch} size="lg"/></Link>
                          )
                        }
                      </div>,
                        placeholder: t('admin.commons.select') + "...",
                        divWidth: {xs: 12, sm: 6},
                        selectOptions: BuildingTypes.map(type => ({
                          value: type,
                          label: t(`${AdminBuildingI18NPrefix}type.${type}`)
                        }))
                      },
                      "block": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.block"),
                        divWidth: {sm: 4},
                      },
                      "floor": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.floor"),
                        divWidth: {xs: 6, sm: 4},
                      },
                      "flat": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.flat"),
                        divWidth: {xs: 6, sm: 4},
                      },
                      "grossArea": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.grossArea"),
                        divWidth: {xs: 4, sm: 4},
                      },
                      "netArea": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.netArea"),
                        divWidth: {xs: 4, sm: 4},
                      },
                      "areaRatio": {
                        type: "valueComponent",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.areaRatio"),
                        warning: areaRatio > 1 && "> 100%",
                        component: (areaRatioDisplay) ?? nullPreview,
                        divWidth: {xs: 4, sm: 4},
                      },
                      "managementFee": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.managementFee") + ` (${t(AdminPropertyI18NPrefix + "basicInfo.monthly")})`,
                        divWidth: {xs: 9, md: 4, xxl: 2}
                      },
                      "managementFeeIncluded": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.included"),
                        divWidth: {xs: 3, md: 2, xxl: 1}
                      },
                      "airConditionerFee": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.airConditionerFee") + ` (${t(AdminPropertyI18NPrefix + "basicInfo.monthly")})`,
                        divWidth: {xs: 9, md: 4, xxl: 2}
                      },
                      "airConditionerFeeIncluded": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.included"),
                        divWidth: {xs: 3, md: 2, xxl: 1}
                      },
                      "rates": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.rates") + ` (${t(AdminPropertyI18NPrefix + "basicInfo.quarterly")})`,
                        divWidth: {xs: 9, md: 4, xxl: 2}
                      },
                      "ratesIncluded": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.included"),
                        divWidth: {xs: 3, md: 2, xxl: 1}
                      },
                      "govRent": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.govRent") + ` (${t(AdminPropertyI18NPrefix + "basicInfo.quarterly")})`,
                        divWidth: {xs: 9, md: 4, xxl: 2}
                      },
                      "govRentIncluded": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.included"),
                        divWidth: {xs: 3, md: 2, xxl: 1}
                      },
                      "hasKey": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.hasKey"),
                        divWidth: {xs: 12, sm: 3, md: 2},
                      },
                      "keyNumber": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.keyNumber"),
                        divWidth: {xs: 6, sm: 4, md: 5},
                        disabled: !basicInfo.hasKey
                      },
                      "isKeyAtManagement": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.isKeyAtManagement"),
                        divWidth: {xs: 6, sm: 5, md: 5},
                        disabled: !basicInfo.hasKey
                      },
                      "view": {
                        type: "select",
                        title: <>{t(AdminPropertyI18NPrefix + "basicInfo.view")}
                          <span className="ms-2">
                            <AdminPropertyViewSelector
                              mini
                              item={(basicInfoHistoryPreview || basicInfo).view}
                              onChange={(view) => {
                                // updateBasicInfo(basicInfo => ({...basicInfo, view}))
                                updateProperty(p => ({...p, basicInfo: {...p.basicInfo, view}}))
                              }}
                              disabled={basicInfoDisabled}
                            />
                          </span>
                          
                        </>,
                        placeholder: t('admin.commons.select') + "...",
                        selectIsClearable: true,
                        selectSearchable: true,
                        divWidth: {xs: 6, lg: 3},
                        selectOptions: propertyViews?.map(view => ({
                          value: view,
                          label: view["name" + LangMap(currentLang)] || view.nameEn
                        })) || [],
                        valueOverride: propertyViews?.find(v => v.nameEn == basicInfo?.view?.nameEn)
                      },
                      "decoration": {
                        type: "select",
                        title: <>{t(AdminPropertyI18NPrefix + "basicInfo.decoration")}
                          <span className="ms-2">
                            <AdminPropertyDecorationSelector
                              mini
                              item={(basicInfoHistoryPreview || basicInfo).decoration}
                              onChange={(decoration) => {
                                // updateBasicInfo(basicInfo => ({...basicInfo, decoration}))
                                updateProperty(p => ({...p, basicInfo: {...p.basicInfo, decoration}}))
                              }}
                              disabled={basicInfoDisabled}
                            />
                          </span>
                        </>,
                        placeholder: t('admin.commons.select') + "...",
                        selectIsClearable: true,
                        selectSearchable: true,
                        divWidth: {xs: 6, lg: 3},
                        selectOptions: propertyDecorations?.map(decoration => ({
                          value: decoration,
                          label: decoration["name" + LangMap(currentLang)] || decoration.nameEn
                        })) || [],
                        valueOverride: propertyDecorations?.find(d => d.nameEn == basicInfo?.decoration?.nameEn)
                      },

                      "direction": {
                        type: "select",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.direction"),
                        placeholder: t('admin.commons.select') + "...",
                        divWidth: {xs: 6, lg: 3},
                        selectOptions: PropertyDirections.map(type => ({
                          value: type,
                          label: t(`${AdminPropertyI18NPrefix}basicInfo.directions.${type}`)
                        }))
                      },

                      "furnish": {
                        type: "select",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.furnish"),
                        placeholder: t('admin.commons.select') + "...",
                        divWidth: {xs: 6, lg: 3},
                        selectOptions: PropertyFurnishTypes.map(type => ({
                          value: type,
                          label: t(`${AdminPropertyI18NPrefix}basicInfo.furnishs.${type}`)
                        }))
                      },

                      "bedroom": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.bedroom"),
                        divWidth: 6,
                        hideNumberComma: true,
                      },
                      "livingRoom": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.livingRoom"),
                        divWidth: 6,
                        hideNumberComma: true,
                      },
                      "balconyArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.balconyArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "gardenArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.gardenArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "terraceArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.terraceArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "roofArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.roofArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "loftArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.loftArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "carportArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.carportArea"),
                        divWidth: {xs:6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "coveredCarParkCount": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.coveredCarPark"),
                        placeholder: t(AdminPropertyI18NPrefix + "basicInfo.carParkCount"),
                        divWidth: {xs: 6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "coveredCarParkInfo": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.coveredCarParkInfo"),
                        divWidth: {xs: 6, sm: 8, lg: 4},
                        hideNumberComma: true,
                      },
                      "uncoveredCarParkCount": {
                        type: "text-int",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.uncoveredCarPark"),
                        placeholder: t(AdminPropertyI18NPrefix + "basicInfo.carParkCount"),
                        divWidth: {xs: 6, sm: 4, lg: 2},
                        hideNumberComma: true,
                      },
                      "uncoveredCarParkInfo": {
                        type: "text",
                        title: t(AdminPropertyI18NPrefix + "basicInfo.uncoveredCarParkInfo"),
                        divWidth: {xs: 6, sm: 8, lg: 4},
                        hideNumberComma: true,
                      }
                    }}
                    onChange={(basicInfo) => {
                      // updateBasicInfo(basicInfo);
                      updateProperty(p => ({...p, basicInfo}))
                    }}
                  />
                </Collapsible>
              )
            },
            "priceStatus": {
              type: "section",
              title: <CollapsibleButton 
                title={t(AdminPropertyI18NPrefix + "priceStatus.title")} 
                collapsed={priceStatusCollapsed} 
              />,
              onClick: () => togglePriceStatusCollapsed()
            },
            "priceStatusSection": {
              type: "entireComponent",
              component: (
                <Collapsible
                  collapsed={priceStatusCollapsed}
                  onExpand={() => togglePriceStatusCollapsed(false)}
                  collapsedComponent={priceStatusCollapsed && <>{
                    PropertyPriceStatusPreview(t, priceStatus)
                  }</>}
                >
                  <EntityData
                    noMarginCompensate
                    object={priceStatusHistoryPreview || priceStatus}
                    disabled={priceStatusDisabled}
                    loading={loading.flag}
                    meta={{
                      "history": {
                        hidden: isNew,
                        type: "entireComponent",
                        component: <>
                          {
                            priceStatusHistory && (
                              <div className="property-history">
                                <AdminTable
                                  sticky
                                  keys={{
                                    date: {content: t('entityData.history.date'), shrink: true},
                                    user: {content: t('entityData.history.user'), shrink: true},
                                    status: {content: t(AdminPropertyI18NPrefix + "priceStatus.status"), shrink: true},
                                    price: {content: t(AdminPropertyI18NPrefix + "priceStatus.priceShort"), shrink: true},
                                    rent: {content: t(AdminPropertyI18NPrefix + "priceStatus.rentShort"), shrink: true},
                                    priceRemark: {content: t(AdminPropertyI18NPrefix + "priceStatus.priceRemark")},
                                    pdfRemark: {content: t(AdminPropertyI18NPrefix + "priceStatus.pdfRemark")},

                                  }}
                                  rows={priceStatusHistory.map(obj => {
                                    const selected = obj == priceStatusHistoryPreview;
                                    return {
                                      selected,
                                      onClick: () => {
                                        setPriceStatusHistoryPreview(oldObj => {
                                          if (obj == oldObj) {
                                            // Replace
                                            // updatePriceStatus(obj);
                                            updateProperty(p => ({...p, priceStatus: obj}))
                                            return null;
                                          } else {
                                            return obj;
                                          }
                                        });
                                      },
                                      content: {
                                        date: moment(obj.updated).format(historyDateFormat),
                                        user: obj.updatedBy?.username,
                                        status: obj.status ? t(AdminPropertyI18NPrefix + "priceStatus.statuses." + obj.status) : nullPreview,
                                        price: obj.price ? `$${commify(obj.price)}` : nullPreview,
                                        rent: obj.rent ? `$${commify(obj.rent)}` : nullPreview,
                                        priceRemark: obj.priceRemark != "" ? obj.priceRemark : nullPreview,
                                        pdfRemark: obj.pdfRemark != "" ? obj.pdfRemark : nullPreview,
                                      }
                                    }
                                  })}
                                />
                              </div>
                            )
                          }
                          <HistoryControl t={t} loading={loading.flag} 
                            open={!!priceStatusHistory} 
                            selected={!!priceStatusHistoryPreview} 
                            timestamp={priceStatusHistoryPreview || priceStatus}
                            onRefresh={async() => {
                              setPriceStatusHistoryPreview(null);
                              const token = loading.start();
                              try {
                                setPriceStatusHistory(await api.property.findPriceStatusHistoryById(property.id));
                              } catch (e) {
                                modal.errorSpecific(e);
                              }
                              loading.stop(token);
                            }}
                            onApply={() => {
                              // updatePriceStatus(priceStatusHistoryPreview);
                              updateProperty(p => ({...p, priceStatus: priceStatusHistoryPreview}))
                              setPriceStatusHistoryPreview(null);
                            }}
                            onCancel={() => setPriceStatusHistoryPreview(null)}                            
                          />
                        </>
                      },
                      "status": {
                        type: "select",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.status"),
                        divWidth: {xs: 12, md: 4},
                        selectOptions: PropertyStatuses.map(type => ({
                          value: type,
                          label: t(`${AdminPropertyI18NPrefix}priceStatus.statuses.${type}`)
                        }))
                      },
                      "source": {
                        type: "select",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.source"),
                        divWidth: {xs: 8, sm: 9, md: 5},
                        selectOptions: PropertySources.map(type => ({
                          value: type,
                          label: t(`${AdminPropertyI18NPrefix}priceStatus.sources.${type}`)
                        }))
                      },
                      "marketable": {
                        type: "switch",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.marketable"),
                        divWidth: {xs: 4, sm: 3},
                      },

                      "price": {
                        type: "text-int",
                        title: <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + "priceStatus.price")}
                          {priceStatus.price == null && <Badge bg="warning" className="ms-2">{t(AdminPropertyI18NPrefix + "priceStatus.sto")}</Badge>}
                        </div>,
                        placeholder: t(AdminPropertyI18NPrefix + "priceStatus.price"),
                        divWidth: {xs: 12, sm: 6},
                        onChange: (price: number | null) => {
                          if (price == null) {
                            // setPriceStatus(priceStatus => ({...priceStatus, pricePerGrossArea: null, pricePerNetArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, pricePerGrossArea: null, pricePerNetArea: null}}))
                          } else {
                            if (basicInfo.grossArea) {
                              const pricePerGrossArea = roundToDp(price / basicInfo.grossArea, 2);
                              // setPriceStatus(priceStatus => ({...priceStatus, pricePerGrossArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, pricePerGrossArea}}))
                            }
                            if (basicInfo.netArea) {
                              const pricePerNetArea = roundToDp(price / basicInfo.netArea, 2);
                              // setPriceStatus(priceStatus => ({...priceStatus, pricePerNetArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, pricePerNetArea}}))
                            }
                          }
                          
                        }
                      },
                      "pricePerGrossArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.pricePerGrossArea"),
                        divWidth: {xs: 6, sm: 3},
                        onChange: (pricePerGrossArea: number | null) => {
                          if (pricePerGrossArea == null) {
                            // setPriceStatus(priceStatus => ({...priceStatus, price: null, pricePerNetArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, price: null, pricePerNetArea: null}}))
                          }
                          else if (basicInfo.grossArea) {
                            const price = roundToDp(pricePerGrossArea * basicInfo.grossArea, 0);
                            // setPriceStatus(priceStatus => ({...priceStatus, price}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, price}}));
                            if (basicInfo.netArea) {
                              const pricePerNetArea = roundToDp(pricePerGrossArea * basicInfo.grossArea / basicInfo.netArea, 2);
                              // setPriceStatus(priceStatus => ({...priceStatus, pricePerNetArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, pricePerNetArea}}))
                            }
                          }
                        }
                      },
                      "pricePerNetArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.pricePerNetArea"),
                        divWidth: {xs: 6, sm: 3},
                        onChange: (pricePerNetArea: number | null) => {
                          if (pricePerNetArea == null) {
                            // setPriceStatus(priceStatus => ({...priceStatus, price: null, pricePerGrossArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, price: null, pricePerGrossArea: null}}));
                          }
                          else if (basicInfo.netArea) {
                            const price = roundToDp(pricePerNetArea * basicInfo.netArea, 0);
                            // setPriceStatus(priceStatus => ({...priceStatus, price}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, price}}));
                            if (basicInfo.grossArea) {
                              const pricePerGrossArea = roundToDp(pricePerNetArea * basicInfo.netArea / basicInfo.grossArea, 2);
                              // setPriceStatus(priceStatus => ({...priceStatus, pricePerGrossArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, pricePerGrossArea}}));
                            }
                          }
                        }
                      },


                      "rent": {
                        type: "text-int",
                        title: <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + "priceStatus.rent")}
                          {priceStatus.rent == null && <Badge bg="warning" className="ms-2">{t(AdminPropertyI18NPrefix + "priceStatus.sto")}</Badge>}
                        </div>,
                        placeholder: t(AdminPropertyI18NPrefix + "priceStatus.rent"),
                        divWidth: {xs: 12, sm: 6},
                        onChange: (rent: number | null) => {
                          if (rent == null) {
                            // setPriceStatus(rentStatus => ({...rentStatus, rentPerGrossArea: null, rentPerNetArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rentPerGrossArea: null, rentPerNetArea: null}}));
                          } else {
                            if (basicInfo.grossArea) {
                              const rentPerGrossArea = roundToDp(rent / basicInfo.grossArea, 2);
                              // setPriceStatus(rentStatus => ({...rentStatus, rentPerGrossArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rentPerGrossArea}}));
                            }
                            if (basicInfo.netArea) {
                              const rentPerNetArea = roundToDp(rent / basicInfo.netArea, 2);
                              // setPriceStatus(rentStatus => ({...rentStatus, rentPerNetArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rentPerNetArea}}));
                            }
                          }
                          
                        }
                      },
                      "rentPerGrossArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.rentPerGrossArea"),
                        divWidth: {xs: 6, sm: 3},
                        onChange: (rentPerGrossArea: number | null) => {
                          if (rentPerGrossArea == null) {
                            // setPriceStatus(rentStatus => ({...rentStatus, rent: null, rentPerNetArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rent: null, rentPerNetArea: null}}));
                          }
                          else if (basicInfo.grossArea) {
                            const rent = roundToDp(rentPerGrossArea * basicInfo.grossArea, 0);
                            // setPriceStatus(rentStatus => ({...rentStatus, rent}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rent}}));
                            if (basicInfo.netArea) {
                              const rentPerNetArea = roundToDp(rentPerGrossArea * basicInfo.grossArea / basicInfo.netArea, 2);
                              // setPriceStatus(rentStatus => ({...rentStatus, rentPerNetArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rentPerNetArea}}));
                            }
                          }
                        }
                      },
                      "rentPerNetArea": {
                        type: "text-float",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.rentPerNetArea"),
                        divWidth: {xs: 6, sm: 3},
                        onChange: (rentPerNetArea: number | null) => {
                          if (rentPerNetArea == null) {
                            // setPriceStatus(rentStatus => ({...rentStatus, rent: null, rentPerGrossArea: null}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rent: null, rentPerGrossArea: null}}));
                          }
                          else if (basicInfo.netArea) {
                            const rent = roundToDp(rentPerNetArea * basicInfo.netArea, 0);
                            // setPriceStatus(rentStatus => ({...rentStatus, rent}));
                            updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rent}}));
                            if (basicInfo.grossArea) {
                              const rentPerGrossArea = roundToDp(rentPerNetArea * basicInfo.netArea / basicInfo.grossArea, 2);
                              // setPriceStatus(rentStatus => ({...rentStatus, rentPerGrossArea}));
                              updateProperty(p => ({...p, priceStatus: {...p.priceStatus, rentPerGrossArea}}));
                            }
                          }
                        }
                      },

                      "priceRemark" :{
                        type: "text-area",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.priceRemark"),
                      },

                      "pdfRemark": {
                        type: "text-area",
                        title: t(AdminPropertyI18NPrefix + "priceStatus.pdfRemark"),
                      },
                    }}
                    onChange={(priceStatus) => updateProperty(p => ({...p, priceStatus}))}
                  />
                </Collapsible>
              )
            },

            "landlord": {
              type: "section",
              title: <CollapsibleButton 
                title={t(AdminPropertyI18NPrefix + "landlord.title")} 
                collapsed={landlordCollapsed} 
              />,
              onClick: () => toggleLandlordCollapsed()
            },
            "landlordSection": {
              type: "entireComponent",
              component: (
                <Collapsible 
                  collapsed={landlordCollapsed}
                  onExpand={() => toggleLandlordCollapsed(false)}
                  collapsedComponent={landlordCollapsed && landlord && <div className="d-flex flex-column" style={{gap: "0.6rem"}}>
                    {landlord.name.trim() != "" && <div className="text-center">{landlord.name}</div>}
                    {
                      <LandlordContactsPreview t={t} landlord={landlord} />
                    }
                    
                  </div>}
                >
                  <EntityData
                    noMarginCompensate
                    object={landlordHistoryPreview || landlord}
                    loading={loading.flag}
                    disabled={landlordDisabled}
                    meta={{
                      "history": {
                        hidden: isNew,
                        type: "entireComponent",
                        component: <>
                          {
                            landlordHistory && (
                              <div className="property-history">
                                <AdminTable
                                  sticky
                                  keys={{
                                    date: {content: t('entityData.history.date'), shrink: true},
                                    user: {content: t('entityData.history.user'), shrink: true},
                                    name: {content: t(AdminPropertyI18NPrefix + "landlord.name")},
                                  }}
                                  rows={landlordHistory.map(obj => {
                                    const selected = obj == landlordHistoryPreview;
                                    return {
                                      selected,
                                      onClick: () => {
                                        setLandlordHistoryPreview(oldObj => {
                                          if (obj == oldObj) {
                                            // Replace
                                            // updateLandlord(obj);
                                            updateProperty(p => ({...p, landlord: oldObj}));
                                            return null;
                                          } else {
                                            return obj;
                                          }
                                        });
                                      },
                                      content: {
                                        date: moment(obj.updated).format(historyDateFormat),
                                        user: obj.updatedBy?.username,
                                        name: obj.name != "" ? obj.name : nullPreview
                                      }
                                    }
                                  })}
                                />
                              </div>
                            )
                          }
                          <HistoryControl t={t} loading={loading.flag} 
                            open={!!landlordHistory} 
                            selected={!!landlordHistoryPreview} 
                            timestamp={landlordHistoryPreview || landlord}
                            onRefresh={async() => {
                              setLandlordHistoryPreview(null);
                              const token = loading.start();
                              try {
                                setLandlordHistory(await api.property.findLandlordHistoryById(property.id));
                              } catch (e) {
                                modal.errorSpecific(e);
                              }
                              loading.stop(token);
                            }}
                            onApply={() => {
                              // updateLandlord(landlordHistoryPreview);
                              updateProperty(p => ({...p, landlord: landlordHistoryPreview}));
                              setLandlordHistoryPreview(null);
                            }}
                            onCancel={() => setLandlordHistoryPreview(null)}                            
                          />
                        </>
                      },
                      "clearAll": {
                        type: "entireComponent",
                        component: (
                          <div>
                            <ButtonWithLoader
                              faIcon={faEraser}
                              variant="warning"
                              onClick={async() => {
                                if (await modal.confirmDelete()) {
                                  // updateLandlord(landlord => (new PropertyLandlord));
                                  updateProperty(p => ({...p, landlord: new PropertyLandlord}));

                                  setShowContactCount(1);
                                }
                              }}
                            >{t('commons.clearAll')}</ButtonWithLoader>
                          </div>
                        )
                      },
                      "name": {
                        type: "text-area",
                        title: <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + "landlord.name")}
                          {
                            landlord?.name && (
                              <Link target="_blank" to={"..?" + APIEncodeQueryString({...FindAllQuery.toJson({
                                filter: {"landlord.name": createEntityFilter("contains", landlord?.name)}
                              }), includeHistory: true, query: landlord?.name})} className="ms-2"><FontAwesomeIcon icon={faSearch} size="lg" /></Link>
                            )
                          }
                        </div>

                      },
                      ... (
                        createNumberArray(1, PropertyLandlordContactCount).reduce((acc, i) => {
                          const hidden = !landlordHistoryPreview && i > showContactCount;
                          return {
                            ...acc, 
                            ["contactName" + i]: {
                              type: "text",
                              divWidth: {xs: 6, md: 4},
                              hidden,
                              title: <div className="d-flex align-items-center">
                                {t(AdminPropertyI18NPrefix + "landlord.contactName") + ` ` + i}
                                {
                                  landlord?.["contactName" + i] && (
                                    <Link target="_blank" to={"..?" + APIEncodeQueryString({...FindAllQuery.toJson({
                                      filter: {["landlord.contactName" + i]: createEntityFilter("contains", landlord?.["contactName" + i])}
                                    }), includeHistory: true, query: landlord?.["contactName" + i]})} className="ms-2"><FontAwesomeIcon icon={faSearch} size="lg"/></Link>
                                  )
                                }
                              </div>,
                              placeholder: t(AdminPropertyI18NPrefix + "landlord.contactName") + ` ` + i
                            },
                            ["contactTitle" + i]: {
                              type: "select",
                              divWidth: {xs: 6, md: 3},
                              hidden,
                              title: t(AdminPropertyI18NPrefix + "landlord.contactTitle"),
                              selectOptions: PropertyContactTitles.map(type => ({
                                value: type,
                                label: t(`${AdminPropertyI18NPrefix}landlord.contactTitles.${type}`)
                              }))
                            },
                            ["contactInfo" + i]: {
                              type: "valueComponent",
                              divWidth: {xs: 12, md: 5},
                              hidden,
                              title: <div className="d-flex align-items-center">
                                {t(AdminPropertyI18NPrefix + "landlord.contactInfo")}
                              </div>,
                              component: (
                                <div className="d-flex w-100 flex-column" style={{gap: "0.3rem"}}>
                                  {
                                    [...(landlordHistoryPreview || landlord)?.["contactInfo" + i], ""]?.map((contactInfo: string, index: number) => {

                                      return (
                                        <div key={index} className="d-flex">
                                          <GenericInput type="text"
                                            value={contactInfo}
                                            disabled={landlordDisabled}
                                            placeholder={t(AdminPropertyI18NPrefix + "landlord.contactInfo")}
                                            onChange={(str) => {
                                              // Disallow ","
                                              str = str.replace(",", "");
                                              const contactInfoList = landlord["contactInfo" + i];

                                              if (index == contactInfoList.length) {
                                                contactInfoList.push(str);
                                              } else {
                                                contactInfoList[index] = str;
                                                if (str == "") {
                                                  let allEmptyAfter = true;
                                                  for (let j = index; j < contactInfoList.length; ++j) {
                                                    if (contactInfoList[j] != "") {
                                                      allEmptyAfter = false;
                                                      break;
                                                    }
                                                  }
                                                  if (allEmptyAfter) {
                                                    contactInfoList.splice(index);
                                                  }
                                                }
                                              }
                                              // updateLandlord({...landlord})
                                              updateProperty(p => ({...p, landlord: {...p.landlord}}));
                                            }}
                                          />
                                          {
                                            contactInfo && (
                                              <Link target="_blank" to={"..?" + APIEncodeQueryString({...FindAllQuery.toJson({
                                                filter: {["landlord.contactInfo"]: createEntityFilter("contains", contactInfo)}
                                              }), includeHistory: true, query: contactInfo})} className="ms-2 d-flex align-items-center disabled"
                                                
                                              ><FontAwesomeIcon icon={faSearch} size="lg"/></Link>
                                            )
                                          }
                                          <ContactShortcut className="ms-2" contact={contactInfo} />
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              ),
                              // postfix: <ContactShortcut className="ms-2" contact={(landlord || landlordHistoryPreview)?.["contactInfo" + i]?.[0]} />
                            },
                          }
                        }, {})
                      ),
                      "contactControl": {
                        type: "entireComponent",
                        hidden: landlordDisabled,
                        component: (
                          <div className="d-flex align-items-center justify-content-end" style={{gap: "0.3rem"}}>
                            {showContactCount < PropertyLandlordContactCount && <ButtonWithLoader faIcon={faPlus} size="sm" variant="success"
                              onClick={() => {setShowContactCount(showContactCount+1)}}
                            />}
                            {showContactCount > 1 && (<ButtonWithLoader faIcon={faMinus} size="sm" variant="danger"
                              onClick={async() => {
                                if (landlord["contactName" + showContactCount] != "" || landlord["contactTitle" + showContactCount] || landlord["contactInfo" + showContactCount]?.[0] != "") {
                                  if (await modal.confirmDelete()) {
                                    // setLandlord(landlord => ({
                                    //   ...landlord,
                                    //   ["contactName" + showContactCount]: "",
                                    //   ["contactTitle" + showContactCount]: null,
                                    //   ["contactInfo" + showContactCount]: ""
                                    // }))
                                    
                                    updateProperty(p => ({...p, landlord: {...p.landlord,
                                      ["contactName" + showContactCount]: "",
                                      ["contactTitle" + showContactCount]: null,
                                      ["contactInfo" + showContactCount]: ""
                                    }}));
                                  } else {
                                    return;
                                  }
                                }
                                setShowContactCount(showContactCount-1)
                              }}
                            />)}
                          </div>
                        )
                      },
                      "visibility": {
                        type: "select",
                        title: t(AdminPropertyI18NPrefix + "landlord.visibility"),
                        selectOptions: PropertyContactVisibilities.map(type => ({
                          value: type,
                          label: t(`${AdminPropertyI18NPrefix}landlord.visibilities.${type}`)
                        }))
                      },
                      // "contactName1": {
                      //   type: "text",
                      //   divWidth: {xs: 8, md: 4},
                      //   title: t(AdminPropertyI18NPrefix + "landlord.contactName")
                      // },
                      // "contactTitle1": {
                      //   type: "select",
                      //   divWidth: {xs: 4, md: 2},
                      //   title: t(AdminPropertyI18NPrefix + "landlord.contactTitle"),
                      //   selectOptions: PropertyContactTitles.map(type => ({
                      //     value: type,
                      //     label: t(`${AdminPropertyI18NPrefix}landlord.contactTitles.${type}`)
                      //   }))
                      // },
                      // "contactInfo1": {
                      //   type: "text",
                      //   divWidth: {xs: 12, md: 6},
                      //   title: t(AdminPropertyI18NPrefix + "landlord.contactInfo")
                      // }
                    }}
                    onChange={(landlord) => updateProperty(p => ({...p, landlord}))}
                  />
                </Collapsible>
              )
            },

            "detail": {
              type: "section",
              title: <CollapsibleButton 
                title={t(AdminPropertyI18NPrefix + "detail.title")} 
                collapsed={detailCollapsed} 
              />,
              onClick: () => toggleDetailCollapsed()
            },
            "detailSection": {
              type: "entireComponent",
              component: (
                <Collapsible 
                  collapsed={detailCollapsed}
                  onExpand={() => toggleDetailCollapsed(false)}
                  collapsedComponent={detailCollapsed && detail && <div className="text-center">
                    {detail.internalRemarks.map((str, index) => <div key={index}>{str}</div>)}
                  </div>}
                >
                  <EntityData
                    noMarginCompensate
                    object={detailHistoryPreview || detail}
                    loading={loading.flag}
                    disabled={detailDisabled}
                    meta={{
                      "history": {
                        hidden: isNew,
                        type: "entireComponent",
                        component: <>
                          {
                            detailHistory && (
                              <div className="property-history">
                                <AdminTable
                                  sticky
                                  keys={{
                                    date: {content: t('entityData.history.date'), shrink: true},
                                    user: {content: t('entityData.history.user'), shrink: true},
                                    internalRemarks: {content: t(AdminPropertyI18NPrefix + "detail.internalRemarks")},
                                    // publicRemark: {content: t(AdminPropertyI18NPrefix + "detail.publicRemark")},
                                  }}
                                  rows={detailHistory.map(obj => {
                                    const selected = obj == detailHistoryPreview;
                                    // const publicRemark = [obj["publicRemark" + LangMap(currentLang)] as string, 
                                    //   obj.publicRemarkEn, obj.publicRemarkTc, obj.publicRemarkSc].find(
                                    //     str => str && str.trim() != ""
                                    //   );
                                    return {
                                      selected,
                                      onClick: () => {
                                        setDetailHistoryPreview(oldObj => {
                                          if (obj == oldObj) {
                                            // Replace
                                            updateProperty(p => ({...p, detail: obj}))
                                            return null;
                                          } else {
                                            return obj;
                                          }
                                        });
                                      },
                                      content: {
                                        date: moment(obj.updated).format(historyDateFormat),
                                        user: obj.updatedBy?.username,
                                        internalRemarks: (obj.internalRemarks.map((str, index) => <div key={index}>{str}</div>)),
                                        // publicRemark: (publicRemark ?? nullPreview),
                                      }
                                    }
                                  })}
                                />
                              </div>
                            )
                          }
                          <HistoryControl t={t} loading={loading.flag} 
                            open={!!detailHistory} 
                            selected={!!detailHistoryPreview} 
                            timestamp={detailHistoryPreview || detail}
                            onRefresh={async() => {
                              setDetailHistoryPreview(null);
                              const token = loading.start();
                              try {
                                setDetailHistory(await api.property.findDetailHistoryById(property.id));
                              } catch (e) {
                                modal.errorSpecific(e);
                              }
                              loading.stop(token);
                            }}
                            onApply={() => {
                              // updateDetail(detailHistoryPreview);
                              updateProperty(p => ({...p, detail: detailHistoryPreview}))
                              setDetailHistoryPreview(null);
                            }}
                            onCancel={() => setDetailHistoryPreview(null)}                            
                          />
                        </>
                      },
                      // "quickRemark": {
                      //   type: "text-area",
                      //   title: t(AdminPropertyI18NPrefix + "detail.quickRemark")
                      // },
                      "internalRemarks": {
                        type: "valueComponent",
                        title: t(AdminPropertyI18NPrefix + "detail.internalRemarks"),
                        component: (
                          <div className="w-100">
                            {/* <ul>
                              {(detailHistoryPreview || detail).internalRemarks.map((str, index) => (
                                <li key={index}>{str}</li>
                              ))}
                            </ul> */}
                            <div className="w-100 d-flex flex-column" style={{gap: "0.3rem"}}>{
                              (detailHistoryPreview?.internalRemarks && [null, ...(detailHistoryPreview?.internalRemarks ?? [])] 
                                || [...detail?.internalRemarks, ""]).map((str, index, arr) => str != null ? (
                                <GenericInput
                                  key={index}
                                  placeholder={"＋ " + t(AdminPropertyI18NPrefix + "detail.internalRemarks")}
                                  type="text"
                                  value={str}
                                  disabled={!!detailHistoryPreview}
                                  onChange={(newStr) => {
                                    const internalRemarks = (detailHistoryPreview || detail).internalRemarks;
                                    if (internalRemarks.length == index) {
                                      internalRemarks.push(newStr);
                                    } else {
                                      internalRemarks[index] = newStr;
                                      if (newStr == "") {
                                        let allEmptyAfter = true;
                                        for (let j = index; j < internalRemarks.length; ++j) {
                                          if (internalRemarks[j] != "") {
                                            allEmptyAfter = false;
                                            break;
                                          }
                                        }
                                        if (allEmptyAfter) {
                                          internalRemarks.splice(index);
                                        }
                                      }
                                    }

                                    updateProperty(p => ({...p}));
                                  }}
                                />
                              ) : arr.length == 1 && <NullDisplay />)
                            }</div>
                            
                            
                          </div>
                          
                        )
                      },
                      // "publicRemarkEn": {
                      //   type: "text-area",
                      //   title: (
                      //     <div className="d-flex">
                      //       <div>{t(AdminPropertyI18NPrefix + "detail.publicRemark")}</div>
                      //       <Badge bg="info" className="ms-2 d-flex align-items-center">EN</Badge>
                      //     </div>
                      //   ),
                      //   placeholder: t(AdminPropertyI18NPrefix + "detail.publicRemark") + " - EN"
                      // },
                      // "publicRemarkTc": {
                      //   type: "text-area",
                      //   title: (
                      //     <div className="d-flex">
                      //       <div>{t(AdminPropertyI18NPrefix + "detail.publicRemark")}</div>
                      //       <Badge bg="info" className="ms-2 d-flex align-items-center">繁</Badge>
                      //     </div>
                      //   ),
                      //   placeholder: t(AdminPropertyI18NPrefix + "detail.publicRemark") + " - 繁",
                      //   onChange: async(value: string) => {
                          
                      //     const {token, signal} = openCCT2SRemarkLoading.start();
                      //     try {
                      //       const text = (await api.openCC.convertHK2S({text: value}, {signal}))?.toString();
                      //       // setDetail(detail => ({...detail, publicRemarkSc: text}))
                      //       updateProperty(p => ({...p, detail: {...p.detail, publicRemarkSc: text}}))
                      //     } catch (e) {
                      //       // modal.errorSpecific(e);
                      //     } finally {
                      //       openCCT2SRemarkLoading.stop(token);
                      //     }
    
                      //   }
                      // },
                      // "publicRemarkSc": {
                      //   type: "text-area",
                      //   title: (
                      //     <div className="d-flex align-items-center">
                      //       <div>{t(AdminPropertyI18NPrefix + "detail.publicRemark")}</div>
                      //       <Badge bg="info" className="ms-2 d-flex align-items-center">简</Badge>
                      //       {
                      //         openCCT2SRemarkLoading.flag && (
                      //           <Spinner animation="border" className="ms-2 text-info" style={{width: "1rem", height: "1rem", borderWidth: "0.15rem"}} />
                      //         )
                      //       }
                      //     </div>
                      //   ),
                      //   placeholder: t(AdminPropertyI18NPrefix + "detail.publicRemark") + " - 简",
                      //   onChange: () => openCCT2SRemarkLoading.abort()
                      // },
                      
                    }}
                    onChange={(detail) => updateProperty(p => ({...p, detail}))}
                  />
                </Collapsible>
              )
            },
            "imageToolSection": {
              type: "section",
              title: t(AdminBuildingAndPropertyImageToolI18NPrefix + "title"),
            },
            "imageTool": {
              type: "component",
              component: (
                <BuildingAndPropertyImageTool
                  buildingId={basicInfo?.building?.id}
                  propertyId={property?.id}
                  eager
                  api={api}
                />
                
              )
            },
            "pdfSection": {
              type: "section",
              title: t(AdminPropertyI18NPrefix + "pdf.title"),
              hidden: isNew,
            },
            "pdf": {
              type: "entireComponent", 
              hidden: isNew,
              component: <PropertyPdfGenerator 
                api={api}
                loading={loading.flag}
                onGenerate={(lang) => {
                  const building = basicInfo?.building;
                  const contactName = [adminUser?.profile?.firstNameEn, adminUser?.profile?.lastNameEn].filter(str => str && str != "").join(" ");
                  const contactNumber = adminUser.profile.contactNumber;

                  const isSale = ["Sale", "Sold", "SaleAndLease"].includes(priceStatus?.status);
                  const isRent = ["Lease", "Leased", "SaleAndLease"].includes(priceStatus?.status);
                  const emptyStringToNull = (str: string) => {
                    if (str && str.trim() != "") {
                      return str;
                    } else {
                      return null;
                    }
                  }

                  return {
                    lang,
                    title1: (
                      " " + (building?.["nameTc"] ?? "") + " "
                    ) + "\n" + (
                      " " + (building?.["nameEn"] ?? " ") + " "
                    ),
                    title2: (
                      " " + StreetPreview(t, {
                        streetName: building?.streetNameTc, 
                        streetNumber: building?.streetNumber,
                        lang: "tc"
                      }) + " "
                    ) + "\n" + (
                      " " + StreetPreview(t, {
                        streetName: building?.streetNameEn, 
                        streetNumber: building?.streetNumber,
                        lang: "en"
                      }) + " "
                    ),
                    details: [
                      {
                        key: t(AdminBuildingI18NPrefix + "district", {lng: lang}),
                        value: building?.district?.["name" + LangMap(lang)]
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.unit", {lng: lang}),
                        value: PropertyBasicInfoPreview(t, {
                          block: basicInfo?.block,
                          floor: basicInfo?.floor,
                          flat: basicInfo?.flat,
                          lang
                        }),
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.grossArea", {lng: lang}),
                        value: commify(basicInfo?.grossArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.grossArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.netArea", {lng: lang}),
                        value: commify(basicInfo?.netArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.netArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.rooms", {lng: lang}),
                        value: PropertyBasicInfoRoomPreview(t, {
                          bedroom: basicInfo.bedroom, livingRoom: basicInfo.livingRoom, lang
                        })
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.balconyArea", {lng: lang}),
                        value: commify(basicInfo?.balconyArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.balconyArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.gardenArea", {lng: lang}),
                        value: commify(basicInfo?.gardenArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.gardenArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.terraceArea", {lng: lang}),
                        value: commify(basicInfo?.terraceArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.terraceArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.roofArea", {lng: lang}),
                        value: commify(basicInfo?.roofArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.roofArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.loftArea", {lng: lang}),
                        value: commify(basicInfo?.loftArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.loftArea), lng: lang
                        }) 
                      }, {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.carportArea", {lng: lang}),
                        value: commify(basicInfo?.carportArea) && t(AdminPropertyI18NPrefix + "basicInfo.areaUnitDisplayApprox", {
                          value: commify(basicInfo?.carportArea), lng: lang
                        }) 
                      },


                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.view", {lng: lang}),
                        value: basicInfo?.view?.["name" + LangMap(lang)]
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.decoration", {lng: lang}),
                        value: basicInfo?.decoration?.["name" + LangMap(lang)]
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.direction", {lng: lang}),
                        value: basicInfo?.direction && t(AdminPropertyI18NPrefix + `basicInfo.directions.${basicInfo?.direction}`, {lng: lang})
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.furnish", {lng: lang}),
                        value: basicInfo?.furnish && basicInfo?.furnish != "None" && t(AdminPropertyI18NPrefix + `basicInfo.furnishs.${basicInfo?.furnish}`, {lng: lang})
                      },

                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.carparks", {lng: lang}),
                        value: [
                          basicInfo.coveredCarParkCount && (
                            t(AdminPropertyI18NPrefix + "basicInfo.coveredCarParkCount", {count: basicInfo.coveredCarParkCount, lng: lang}) +
                            (basicInfo.coveredCarParkInfo != "" ? ` (${basicInfo.coveredCarParkInfo})` : "")
                          ),
                          basicInfo.uncoveredCarParkCount && (
                            t(AdminPropertyI18NPrefix + "basicInfo.uncoveredCarParkCount", {count: basicInfo.uncoveredCarParkCount, lng: lang}) +
                            (basicInfo.uncoveredCarParkInfo != "" ? ` (${basicInfo.uncoveredCarParkInfo})` : "")
                          )
                        ].filter(str => (str && str != "")).join(" / ")
                      },

                      isSale && priceStatus.price && {
                        key: t(AdminPropertyI18NPrefix + "priceStatus.price", {lng: lang}),
                        value: [
                          "HKD$" + commify(priceStatus.price),
                          (priceStatus.pricePerGrossArea || priceStatus.pricePerNetArea) && (t(AdminPropertyI18NPrefix + "priceStatus.unitPrice", {lng: lang}) + ": " + (
                            [
                              priceStatus.pricePerGrossArea && (
                                `$${commify(priceStatus.pricePerGrossArea)} (${t(AdminPropertyI18NPrefix + "basicInfo.grossAreaVeryShort", {lng: lang})})`
                              ),
                              
                              priceStatus.pricePerNetArea && (
                                `$${commify(priceStatus.pricePerNetArea)} (${t(AdminPropertyI18NPrefix + "basicInfo.netAreaVeryShort", {lng: lang})})`
                              ),
                            ].filter(val => !!val).join(", ")
                          ))
                        ].filter(val => !!val).join("\n")
                      },

                      isRent && priceStatus.rent && {
                        key: t(AdminPropertyI18NPrefix + "priceStatus.rent", {lng: lang}),
                        value: [
                          "HKD$" + commify(priceStatus.rent),
                          (priceStatus.rentPerGrossArea || priceStatus.rentPerNetArea) && (t(AdminPropertyI18NPrefix + "priceStatus.unitRent", {lng: lang}) + ": " + (
                            [
                              priceStatus.rentPerGrossArea && (
                                `$${commify(priceStatus.rentPerGrossArea)} (${t(AdminPropertyI18NPrefix + "basicInfo.grossAreaVeryShort", {lng: lang})})`
                              ),
                              
                              priceStatus.rentPerNetArea && (
                                `$${commify(priceStatus.rentPerNetArea)} (${t(AdminPropertyI18NPrefix + "basicInfo.netAreaVeryShort", {lng: lang})})`
                              ),
                            ].filter(val => !!val).join(", ")
                          ))
                        ].filter(val => !!val).join("\n")
                      },

                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.managementFee", {lng: lang}),
                        value: basicInfo.managementFee && (
                          `${t(AdminPropertyI18NPrefix + "basicInfo.monthly", {lng: lang})}${t("commons.wordDelimiter", {lng: lang})}HKD$${commify(basicInfo.managementFee)}`
                        )
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.airConditionerFee", {lng: lang}),
                        value: basicInfo.airConditionerFee && (
                          `${t(AdminPropertyI18NPrefix + "basicInfo.monthly", {lng: lang})}${t("commons.wordDelimiter", {lng: lang})}HKD$${commify(basicInfo.airConditionerFee)}`
                        )
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.rates", {lng: lang}),
                        value: basicInfo.rates && (
                          `${t(AdminPropertyI18NPrefix + "basicInfo.quarterly", {lng: lang})}${t("commons.wordDelimiter", {lng: lang})}HKD$${commify(basicInfo.rates)}`
                        )
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "basicInfo.govRent", {lng: lang}),
                        value: basicInfo.govRent && (
                          `${t(AdminPropertyI18NPrefix + "basicInfo.quarterly", {lng: lang})}${t("commons.wordDelimiter", {lng: lang})}HKD$${commify(basicInfo.govRent)}`
                        )
                      },

                      {
                        key: t(AdminBuildingI18NPrefix + "occupationDateShort", {lng: lang}),
                        value: [building?.occupationYear, building?.occupationMonth, building?.occupationDay].filter(
                          str => !!str
                        ).join("/")
                      },

                      {
                        key: t("admin.developers.title", {lng: lang}),
                        value: building?.developers?.map(developer => {
                          let name = developer?.["name" + LangMap(lang)]
                          // Fallback to other languages if empty
                          Langs.forEach((tempLang) => {
                            if (!name || name.trim() == "") {
                              name = developer?.["name" + LangMap(tempLang)]
                            }
                          })
                          return name;
                        })?.join(" ")
                      },

                      {
                        key: t(AdminPropertyI18NPrefix + "pdf.content.remarks", {lng: lang}),
                        value: priceStatus.pdfRemark
                      },

                      {
                        key: t(AdminPropertyI18NPrefix + "pdf.content.commission", {lng: lang}),
                        value: [
                          isSale && (t(AdminPropertyI18NPrefix + "pdf.content.commissionDefaultForSale", {lng: lang})),
                          isRent && (t(AdminPropertyI18NPrefix + "pdf.content.commissionDefaultForRent", {lng: lang}))
                        ].filter(str => str && str != "").join(" /\n")
                      },
                      {
                        key: t(AdminPropertyI18NPrefix + "refNo", {lng: lang}),
                        value: property.id.toString(),
                      },
                      
                    ],
                    contactLine: (
                      (contactName != "" ? `${contactName} ` : "") + 
                      (contactNumber != "" ? "21569903" + ` / ${contactNumber}` : "")
                    ),
                    licenseNumber: (
                      adminUser.adminInfo?.licenseNumber
                    ),
                    // imageBuildingId: building?.id,
                    // imageFilenames: [],
                    buildingId: building?.id,
                    propertyId: property?.id,
                    
                    // imageBuildingId: building?.id,
                    // imageFilenames: [],
                    imageTitle: PropertyBasicInfoPreview(t, {
                      building: building,
                      block: basicInfo.block,
                      floor: basicInfo.floor,
                      flat: basicInfo.flat,
                      excludeStreet: true,
                      lang
                    }),
                    imageAddWatermark: true,
                    filename: (PropertyBasicInfoPreview(t, {
                      building: building,
                      block: basicInfo.block,
                      floor: basicInfo.floor,
                      flat: basicInfo.flat,
                      excludeStreet: true,
                      lang
                    }))?.replaceAll(/[\/\?]/g, "")
                  };
                }}
              />
            },
            
            "formSection": {
              type: "section",
              title: "Form 3 / 5",
              hidden: isNew || !userCanGenerateForm,
            },

            "form": {
              type: "entireComponent", 
              hidden: isNew || !userCanGenerateForm,
              component: userCanGenerateForm && <Form3Or5Generator api={api}
                onGenerate={(form) => {
                  const {lang, formType} = form;
                  const formUpdated = {...form};

                  formUpdated.refNo = `${property.id || "-"}`;
                  formUpdated.agent = lang == "en" ? "BEST-GROUP INVESTMENT LIMITED" : "俊福行投資有限公司";
                  formUpdated.vendor = property?.landlord?.name || property?.landlord?.contactName1 || "";

                  formUpdated.property = PropertyBasicInfoPreview(t, {
                    building: basicInfo.building,
                    block: basicInfo.block,
                    floor: basicInfo.floor,
                    flat: basicInfo.flat,
                    lang: lang
                  }) || ""

                  if (formType == "form3" && property?.priceStatus?.price) {
                    formUpdated.price = property?.priceStatus?.price;
                    formUpdated.priceShort = numberInShortPublic(formUpdated.price, lang || "en")
                  } else if (formType == "form5" && property?.priceStatus?.rent) {
                    formUpdated.price = property?.priceStatus?.rent;
                    formUpdated.priceShort = numberInShortPublic(formUpdated.price, lang || "en")
                  };
                  formUpdated.floatingRange = 10;

                  formUpdated.signatoryInfo = "Wendy Fan E-100635";
                  formUpdated.agentBusinessNumber = "C-021137";
                  formUpdated.address = lang == "en" ? 
                    "Unit 1610, 16/F, Eton Tower, 8 Hysan Avenue, Causeway Bay, Hong Kong" : 
                    "香港銅鑼灣希慎道8號裕景商業中心16樓1610室";
                  formUpdated.phone = "+852-9469-3091";
                  formUpdated.fax = "+852-2530-4373";

                  const now = new Date();

                  formUpdated.date = now;

                  formUpdated.validatyPeriodFrom = now;
                  formUpdated.validatyPeriodTo = moment(now).add(1, "years").subtract(1, "days").toDate();


                  if (formType == "form3") {
                    formUpdated.commissionPercentage = 1;
                  } else if (formType == "form5") {
                    formUpdated.commissionPercentage = 50;
                  }

                  formUpdated.filename = ((PropertyBasicInfoPreview(t, {
                    building: basicInfo.building,
                    block: basicInfo.block,
                    floor: basicInfo.floor,
                    flat: basicInfo.flat,
                    lang: lang,
                    excludeStreet: true
                  }) || "property") + (
                    formUpdated.formType == "form3" ? " (Form 3)" : " (Form 5)"
                  ))?.replaceAll(/[\/\?]/g, "");

                  return formUpdated;
                }}
              />
            },
            "publishmentSection": {
              hidden: isNew,
              type: "section",
              title: <><a id="publishment-area" /><CollapsibleButton 
                title={t(AdminPropertyI18NPrefix + "publishment.title")} 
                collapsed={publishmentCollapsed} 
              /></>,
              onClick: () => togglePublishmentCollapsed()
            },
            "publishmentState": {
              hidden: isNew,
              type: "component",
              // component: <div className="d-flex align-items-center">
              //   <b>{t(AdminPropertyI18NPrefix + "publishment.publishmentState")}</b>{`:`}
              //   <PublishmentStateDisplay state={property?.publishment?.state} className="ms-2 me-2"/>
              //   {t(AdminPropertyI18NPrefix + "publishment.states." + (property?.publishment?.state || "null"))}

              //   <Link to={"../../" + AdminPropertyPublishmentPath + "/" + id} className="ms-2">
              //     <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
              //   </Link>
              // </div> 
              component: <>
              {
                (property && property?.publishment && property?.publishment?.state !== "unpublished") ? (
                  <Collapsible 
                    collapsed={!property?.publishment || (property?.publishment && publishmentCollapsed)}
                    onExpand={() => togglePublishmentCollapsed(false)}
                    collapsedComponent={publishmentCollapsed && !isNew && <><div className="d-flex align-items-center">
                        <b>{t(AdminPropertyI18NPrefix + "publishment.publishmentState")}</b>{`:`}
                        <PublishmentStateDisplay state={property?.publishment?.state} className="ms-2 me-2"/>
                        {t(AdminPropertyI18NPrefix + "publishment.states." + (property?.publishment?.state || "null"))}

                      
                        <a href={process.env.REACT_APP_PUBLIC_URL + "/property/" + property?.id} className="ms-2" target="_blank">
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
                        </a>
                      </div>
                    </> }
                  >
                    {property ? (
                        <AdminPropertyPublishmentComponentInline
                          api={api}
                          isLoading={loading.flag}
                          property={property}
                          publishment={publishment}
                          onChange={(dispatch) => {
                            updateProperty(p => ({...p, publishment: dispatch(p.publishment)}))
                          }}
                        /> 
                      ) : <></>
                    }
                  </Collapsible>
                ) : (
                  <div className="d-flex mx-auto justify-content-center" style={{gap: "0.3em"}}>
                    {
                      publishment?.state === "unpublished" ? (
                        <ButtonWithLoader variant="warning" faIcon={faPlus}
                          disabled={loading.flag}
                          onClick={() => {
                            // initPublishment();
                            updateProperty(p => ({...p, publishment: {...p.publishment, state: "pending"}}))
                            setPublishmentCollapsed(false);
                          }}
                        >
                          {t(AdminPropertyI18NPrefix + "publishment.republish")}
                        </ButtonWithLoader>
                      ) : (
                        <ButtonWithLoader variant="warning" faIcon={faPlus}
                          disabled={loading.flag}
                          onClick={() => {
                            initPublishment();
                            setPublishmentCollapsed(false);
                          }}
                        >
                          {t(AdminPropertyI18NPrefix + "publishment.title")}
                        </ButtonWithLoader>
                      )
                    }
                  </div>  
                )
              }
              
              </>
            },
            "hr2": {
              type: "hr",
            }
          }}
          onChange={(data) => {
            console.log(data);
            setProperty(data);
            setUnsaved(true);
          }}
          showDeleteOnCtrl
          onDelete={!isNew && (async() => {
            if (id != null && await modal.confirmDelete(true)) {
              let token = loading.start();
              try {
                await api.property.delete(id);
                props.onDelete?.();
              } catch (e) {
                modal.errorSpecific(e);
              }
              loading.stop(token);
            }
          })}
          floatingSaveBtn
          // floatingSaveBtnPrefix={}
          undoRedo={{
            canUndo,
            canRedo,
            onUndo: () => {
              setProperty(p => ({...p, ...undo()}));
              setUnsaved(true);
            },
            onRedo: () => {
              setProperty(p => ({...p, ...redo()}));
              setUnsaved(true);
            }
          }}
          onSave={() => {

            toast.save(async() => {
              openCCT2SRemarkLoading.abort();
              let token = loading.start();
              let {id, ...propertyPending} = property;
              
              const dto: PropertyDto = {
                ...propertyPending,
                basicInfoDto: {
                  ...basicInfo,
                  buildingId: basicInfo?.building?.id,
                  viewNameEn: basicInfo?.view?.nameEn,
                  decorationNameEn: basicInfo?.decoration?.nameEn
                },
                priceStatusDto: priceStatus,
                landlordDto: landlord,
                detailDto: {
                  ...detail,
                },
                publishmentDto: publishment ? {
                  ...publishment,
                  contactUserIds: publishment.contactUsers?.map(u => u.userId),
                  tagIds: publishment.tags?.map(t => t.tagId)
                } : null
              }

              try {
                var propertyUpdated: Property;

                if (mode == "create") {
                  propertyUpdated = await api.property.create(dto);
                  setProperty(propertyUpdated);
                } else if (mode == "edit") {
                  propertyUpdated = await api.property.update(id!, dto);
                  await reload();
                }
                props.onSave?.(propertyUpdated);
                setUnsaved(false);
              } catch (e) {
                modal.errorSpecific(e);
                console.log(e);
                throw e;
              } finally {
                loading.stop(token);
              }

              
            }, "property-save")

          }}
        />
      }

      
    </>
  )

}


export const AdminProperty = (props: AdminPropertyProps) => {
  const navigate = useNavigate();
  // const {api, loading, toast, setSelectedItem, adminUser} = useAdminComponent();
  // const modal = useGlobalModal();
  // const {t, currentLang} = useI18N();
  const {mode} = props;
  const {t} = useI18N();
  
  const {id: idRaw} = useParams<{id: string}>();
  const [searchParams, setSearchParams] = useSearchParams();

  const id: number | null = useMemo(() => {
    if (idRaw == null || mode == "create") {return null;}
    let id = parseInt(idRaw);
    if (isNaN(id) || id.toString() != idRaw) {return null;}
    return id;
  }, [idRaw]);

  const goToPublishment = useMemo(() => {
    return searchParams.get("go-to-publishment") != null
  }, [])

  useEffect(() => {
    if (searchParams.has("go-to-publishment")) {
      searchParams.delete("go-to-publishment");
      setSearchParams(searchParams, {replace: true});
    }
  }, [])

  const title = useMemo(() => {
    switch (mode) {
      case "create": 
        return t(AdminPropertyI18NPrefix + 'createTitle');
      case "edit":
        return `${t(AdminPropertyI18NPrefix + 'editTitle')} (id: ${id ?? "-"})`
    }
  }, [id, mode]);


  return (
    <AdminComponent.Container>
      <AdminPropertyComponent id={id} mode={mode} 
        goToPublishment={goToPublishment}
        onDelete={() => {
          navigate("..");
        }}
      />
    </AdminComponent.Container>
  )

}
